<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Repair</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 30%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
<!-- 
              <v-select
                style="width: 25%"
                id="brand"
                v-model="companySelected"
                label="title"
                :options="companyOption"
                class="ml-1"
                :clearable="false"
              /> -->

              <v-select
                style="width: 20%"
                id="brand"
                v-model="statusSelected"
                label="title"
                :options="statusOption"
                class="ml-1"
                :clearable="false"
              />

              <b-button
                v-if="repairStockPermission=='create' || repairStockPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                v-b-modal.add-stock-repair-modal
                @click="onCreate"
                ><feather-icon icon="PlusCircleIcon" size="14" /> เพิ่ม
              </b-button>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
            
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(repairDate)="row">
                <center>{{ convertDate(row.item.repairDate) }}</center>
              </template>

              <template #cell(status)="row">
                <center>
                  <feather-icon
                    v-if="row.item.status == 'Complete'"
                    icon="CheckCircleIcon"
                    class="cursor-pointer text-primary"
                  />
                  <feather-icon
                    v-else-if="row.item.status == 'Wait'"
                    icon="ClockIcon"
                    class="cursor-pointer text-warning"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    class="cursor-pointer text-danger"
                  />
                </center>
              </template>

              <template #cell(action)="row" v-if="repairStockPermission=='edit'">
                <center>
                  <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    size="sm"
                    pill
                    v-b-modal.add-stock-repair-modal
                    @click="onSelectItemForEdit(row.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer text-secondary"
                    />
                  </b-button>
                  <b-button variant="danger" size="sm" pill @click="deleteRepairStock(row.item)">
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer text-white"
                    />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <AddStockRepairModal @saveAndCloseModal="saveAndCloseModal" :repairStockData="repairStockData" :mode="mode"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import AddStockRepairModal from "./component/modal/addStock.vue";

import RepairStockService from "@/services/stock/repair";
import RepairStockHistoryService from "@/services/stock/repairHistory";
import { GET_CURRENT_DATE } from "@/utils/";

import Field from "./field";
import moment from "moment";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    vSelect,
    AddStockRepairModal,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      statusSelected: { title: "ทุกสถานะ", value:"all" },
      companySelected: { title: "บริษัทถูกและดี" },
      statusOption: [{ title: "ทุกสถานะ", value:"all" }, { title: "รอการตรวจสอบ", value:"Wait" },{ title: "ซ่อมเรียบร้อย", value:"Complete" },  { title: "เสีย", value:"Bad" }],
      companyOption: [{ title: "บริษัทถูกและดี" }, { title: "Milkland" }],
      isLoading:false,
      repairStockData:{},
      mode:"create"
    };
  },

  computed :{
    repairStockPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("STK002")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission       
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("STK002") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }
  },
  watch: {
    statusSelected(newValue, oldValue) {
      this.getRepairStock(newValue.value)
    },
  },
  mounted() {
    this.getRepairStock();
    
  },
  methods: {
    async getRepairStock(status = "all") {
      this.isLoading = true
      const result = await RepairStockService.GetRepairStock(status);
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false
    },
    async saveAndCloseModal(){
      await this.getRepairStock();
    },

    async deleteRepairStock(item){
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm){
        return false
      }

      const result = await RepairStockService.DeleteRepairStock(item.id)
      this.insertHistory(item, "delete")
      await this.getRepairStock()
    },
    insertHistory(obj,type){
      const userProfile = JSON.parse(localStorage.getItem('userData'))
      let item = {...obj}
      delete item.id
      RepairStockHistoryService.InsertRepairStockHistory({...item, createAt:GET_CURRENT_DATE().timestamp, createBy:userProfile.userId, historyType:type})
    },
    onCreate(){
      this.mode="create"
      this.repairStockData = {}
    },
    onSelectItemForEdit(item){
      this.mode = "edit"
      this.repairStockData = item  
    },

    convertDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
