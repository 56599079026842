import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const RepairStockService = () => {}

RepairStockService.GetRepairStock = async(status)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/repair/status/${status}`, {})
}

RepairStockService.GetRepairStockById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/repair/${id}`, {})
}

RepairStockService.InsertRepairStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/repair`, payload)
}



RepairStockService.UpdateRepairStock = async(payload, id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/repair/${id}`, payload)
}

RepairStockService.DeleteRepairStock = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/stock/repair/${id}`, {})
}



 export default RepairStockService