export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white"}
  },
  {
    key: "serial",
    label: "Serial No.",
    thStyle:{color:"white", textAlign: "center"}
  },
  { key: "hwTypeName", label: "Hardware type", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "hwBrandName", label: "Brand", sortable: true ,thStyle:{color:"white",width: "7% !important", textAlign: "center",}},
  { key: "hwModelName", label: "Model", sortable: true ,thStyle:{color:"white",width: "7% !important", textAlign: "center",}},
  { key: "repairDate", label: "วันส่งซ่อม", sortable: true ,thStyle:{color:"white",width: "6% !important", textAlign: "center",}},
  { key: "repairAt", label: "ซ่อมที่", sortable: true ,thStyle:{color:"white",width: "5% !important", textAlign: "center",}},
  { key: "problem", label: "ปัญหา", sortable: true ,thStyle:{color:"white",width: "15% !important", textAlign: "center",}},
  { key: "resolve", label: "วิธีแก้ไข", sortable: true , thStyle:{color:"white", width: "15% !important", textAlign: "center"}},
  { key: "remark", label: "หมายเหตุ", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "branchName", label: "Owner", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "status", label: "สถานะ", sortable: false , thStyle:{color:"white", textAlign: "center"}},
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "10% !important", textAlign: "center", color:"white" },
  },
];
