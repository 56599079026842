<template>
  <div>
    <b-modal
      ref="add-stock-repair-modal"
      id="add-stock-repair-modal"
      :title="title"
      size="lg"
      hide-footer
    >
      <b-overlay
        :show="isLoading"
        rounded="lg"
        opacity="0.55"
        blur="3px"
        variant="transparent"
        fixed
      >
        <b-card-text>
          <!--- START (เลขที่ใบงาน) --->
          <b-row>
            <b-col md="6" class="">
              <label>เลขที่ใบงาน</label>
              <b-form-input
                id="smallInput"
                size="sm"
                placeholder="VST-660001"
                v-model="repairStockData.ticketNo"
              />
            </b-col>
            <b-col md="6" class=""></b-col>
          </b-row>
          <!--- END (เลขที่ใบงาน) --->

          <!--- START (Topic ข้อมูลเครื่อง) --->
          <b-row class="mt-3">
            <b-col>
              <span class="text-primary"><b>ข้อมูลเครื่อง</b></span>
            </b-col>
          </b-row>
          <!--- END (Topic ข้อมูลเครื่อง) --->

          <!--- START (Serial) --->
          <b-row>
            <b-col md="6">
              <label>Serial <span class="text-danger">*</span></label>
              <b-form-input
                id="smallInput"
                size="sm"
                placeholder="1804HJI093DJJ09921"
                v-model="repairStockData.serial"
              />
            </b-col>
            <b-col md="6" class=""></b-col>
          </b-row>
          <!--- END (Serial) --->

          <!--- START (ประเภทอุปกรณ์) --->

          <b-row class="mt-1">
            <b-col md="4" class="">
              <label>ประเภทอุปกรณ์</label>
              <v-select
                id="brand"
                v-model="hwTypeSelected"
                label="hwTypeName"
                :options="hwTypeOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeHwType"
              />
            </b-col>
            <b-col md="4" class="">
              <label>ยี่ห้อ</label>
              <v-select
                id="brand"
                v-model="hwBrandSelected"
                label="hwBrandName"
                :options="hwBrandOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeHwBrand"
              />
            </b-col>
            <b-col md="4" class="">
              <label>รุ่น</label>
              <v-select
                id="brand"
                v-model="hwModelSelected"
                label="hwModelName"
                :options="hwModelOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
          </b-row>

          <!--- END (ประเภทอุปกรณ์) --->

          <!--- START (Topic ร้าน/สาขา) --->
          <b-row class="mt-3">
            <b-col>
              <span class="text-primary"><b>ร้าน/สาขา</b></span>
            </b-col>
          </b-row>
          <!--- END (Topic ร้าน/สาขา) --->

          <!--- START (ลูกค้า) --->
          <b-row>
            <b-col cols="6">
              <label>ลูกค้า</label>
              <v-select
                id="brand"
                v-model="ownerSelected"
                label="ownerName"
                :options="ownerOption"
                :clearable="false"
                class="select-size-sm"
                @input="onChangeCustomerOwner"
              />
            </b-col>
            <b-col cols="6">
              <label>Brand</label>
              <v-select
                id="brand"
                v-model="companySelected"
                label="companyName"
                :options="companyOption"
                :clearable="false"
                class="select-size-sm"
                @input="onChangeCustomerCompany"
              />
            </b-col>
          </b-row>
          <!--- END (ลูกค้า) --->

          <!--- START (ลูกค้า) --->
          <b-row class="">
            <b-col cols="6">
              <label>บริษัท</label>
              <v-select
                id="brand"
                v-model="subCompanySelected"
                label="subCompanyName"
                :options="subCompanyOption"
                :clearable="false"
                class="select-size-sm"
                @input="onChangeCustomerSubCompany"
              />
            </b-col>
            <b-col cols="6">
              <label>สาขา</label>
              <v-select
                id="brand"
                v-model="branchSelected"
                label="branchName"
                :options="branchOption"
                :clearable="false"
                class="select-size-sm"
              />
            </b-col>
          </b-row>
          <!--- END (ลูกค้า) --->

          <!--- START (Topic สถานะ) --->
          <b-row class="mt-3">
            <b-col>
              <span class="text-primary"><b>สถานะและการมอบหมาย</b></span>
            </b-col>
          </b-row>
          <!--- END (Topic สถานะ) --->

          <!--- START (สถานะ) --->
          <b-row>
            <b-col md="4" class="">
              <label>สถานะ</label>
              <v-select
                id="brand"
                v-model="statusSelected"
                label="title"
                :options="statusOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
            <b-col md="4" class="">
              <label>ส่งซ่อมที่</label>
              <v-select
                id="brand"
                v-model="repairAtSelected"
                label="title"
                :options="repairAtOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
            <b-col md="4" class="">
              <label>ส่งให้ช่าง</label>
              <v-select
                id="brand"
                :disabled="mode=='create' || !isCompleted"
                v-model="employeeSelected"
                label="fullname"
                :options="employeeOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
          </b-row>
          <!--- END (สถานะ) --->

          <!--- START (Topic ปัญหาและการแก้ไข) --->
          <b-row class="mt-3">
            <b-col>
              <span class="text-primary"><b>ปัญหาและการแก้ไข</b></span>
            </b-col>
          </b-row>
          <!--- END (Topic ปัญหาและการแก้ไข) --->

           <b-row class="mt-1">
          <b-col md="6" class="">
            <label>วันที่ส่งซ่อม</label>
            <b-form-datepicker
              id="example-datepicker"
              locale="th-TH"
              v-model="repairStockData.repairDate"
              show-decade-nav
              class="mb-1"
              size="sm"
            />
          </b-col>
          
        </b-row>
        <!--- END (วันที่) --->

          <!--- START (ปัญหา) --->
          <b-row class="mt-1">
            <b-col md="12" class="">
              <label>ปัญหา <span class="text-danger">*</span></label>
              <b-form-textarea
                id="textarea-default"
                placeholder="ปัญหา"
                rows="2"
                size="sm"
                v-model="repairStockData.problem"
              />
            </b-col>
          </b-row>
          <!--- END (ปัญหา) --->

          <!--- START (วิธีแก้ไข) --->
          <b-row class="mt-1">
            <b-col md="12" class="">
              <label>วิธีแก้ไข</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="วิธีแก้ไข"
                rows="2"
                size="sm"
                v-model="repairStockData.resolve"
              />
            </b-col>
          </b-row>
          <!--- END (วิธีแก้ไข) --->

          <!--- START (หมายเหตุ) --->
          <b-row class="mt-1">
            <b-col md="12" class="">
              <label>หมายเหตุ</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="หมายเหตุ"
                rows="2"
                size="sm"
                v-model="repairStockData.remark"
              />
            </b-col>
          </b-row>
          <!--- END (หมายเหตุ) --->

       
          <!--- START (BTN SUBMIT) --->
          <b-row class="mt-1">
            <b-col md="12" class="d-flex justify-content-end">
              <b-button
                v-if="repairStockPermission=='create' || repairStockPermission=='edit'"
                variant="primary"
                @click="save"                
                >บันทึก</b-button
              >
            </b-col>
          </b-row>
          <!--- END (BTN SUBMIT) --->
        </b-card-text>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BOverlay,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import UserService from "@/services/user";
import HardwareService from "@/services/setting/hardware";
import RepairStockService from "@/services/stock/repair";
import RepairStockHistoryService from "@/services/stock/repairHistory";
import CustomerService from "@/services/setting/customer";
import { GET_CURRENT_DATE } from "@/utils/";

export default {
  props: ["repairStockData", "mode"],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BOverlay,
    vSelect,
    BFormDatepicker
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      employeeSelected: { userId: 0, fullname: "-" },
      hwTypeSelected: {},
      hwBrandSelected: {},
      hwModelSelected: {},
      statusSelected: { title: "รอการตรวจสอบ", value: "Wait" },
      repairAtSelected: { title: "Vserv", value: "vserv" },
      employeeOption: [{ userId: 0, fullname: "-" }],
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      ownerSelected: {},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      branchOption: [],
      branchSelected: {},
      statusOption: [
        { title: "รอการตรวจสอบ", value: "Wait" },
        { title: "ซ่อมเรียบร้อย", value: "Complete" },
        { title: "เสีย", value: "Bad" },
      ],
      repairAtOption: [
        { title: "Vserv", value: "vserv" },
        { title: "Vtec", value: "vtec" },
        { title: "Synature", value: "synature" },
        { title: "CS Retail", value: "CS Retail" },
      ],
      isLoading: false,
      title: "เพิ่มการซ่อม",
      isCompleted:false,
      userLogin:{}
    };
  },
  async created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;

    this.isLoading = true;
    this.resetData();
    await Promise.all([
      this.getCustomerOwner(),
      this.getHwType(),
      this.getUsers(),
    ]);
    this.isLoading = false;
  },
  computed :{
    repairStockPermission () {
      return this.$store.getters["user/getPermissionPage"]("STK002")
    }
  },
  watch: {
    async repairStockData() {
      if (this.mode == "edit") {
        this.isLoading = true;
        this.title = "แก้ไขการซ่อม";

        await Promise.all([
          this.getCustomerOwner(),
          this.getHwType(),
          this.getUsers(),
        ]);
        await this.restoreData();
        this.isLoading = false;
      }
    },
    statusSelected(item){
      if(item.value!=="Complete"){
        this.isCompleted = false
        this.employeeSelected = { userId: 0, fullname: "-" }
      }else{
        this.isCompleted = true
      }
    }
  },
  methods: {
    validateForm(){
      if(!this.repairStockData.serial){
        alert("กรุณากรอกเลข Serial")
        return true
      }

      if(!this.repairStockData.problem){
        alert("กรุณารายละเอียดปัญหา")
        return true
      }

      return false
    },
    async save() {
      const invalid = this.validateForm()
      if(invalid) return;

      if (this.repairStockData.id) {
        await this.updateRepairStock();
      } else {
        await this.insertRepairStock();
      }
      this.hideModal();
    },
    async insertRepairStock() {
      const obj = {
        ...this.repairStockData,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        hwModelId: this.hwModelSelected.id,
        status: this.statusSelected.value,
        assignTo: this.employeeSelected.userId,
        ownerId: this.ownerSelected.id,
        companyId: this.companySelected.id,
        subCompanyId: this.subCompanySelected.id,
        branchId: this.branchSelected.id,
        repairAt: this.repairAtSelected.value,
        repairDate: this.repairStockData.repairDate,
        createAt: GET_CURRENT_DATE().timestamp,
        createBy:this.userLogin.userId
      };
      const result = await RepairStockService.InsertRepairStock(obj);

      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Repair Stock`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });

        this.insertHistory(obj, "add");
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Repair Stock`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }
    },
    async updateRepairStock() {
      const obj = {
        ...this.repairStockData,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        hwModelId: this.hwModelSelected.id,
        status: this.statusSelected.value,
        assignTo: this.employeeSelected.userId,
        ownerId: this.ownerSelected.id,
        companyId: this.companySelected.id,
        subCompanyId: this.subCompanySelected.id,
        branchId: this.branchSelected.id,
        repairAt: this.repairAtSelected?.value || "",
        repairDate: this.repairStockData.repairDate,
        createAt: GET_CURRENT_DATE().timestamp,
        createBy:this.userLogin.userId
       
      };

      const result = await RepairStockService.UpdateRepairStock(obj, obj.id);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Stock Repair`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });

        delete obj.id
        this.insertHistory(obj, "update");
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Stock Repair`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
    },

    insertHistory(obj, type) {
      const userProfile = JSON.parse(localStorage.getItem("userData"));
      RepairStockHistoryService.InsertRepairStockHistory({
        ...obj,       
        createBy: userProfile.userId,
        historyType: type,
      });
    },
    async getUsers() {
      const result = await UserService.GetUserList({});
      if (result.data.data.length > 0) {
        if (this.mode == "create") {
          this.employeeOption = [...this.employeeOption, ...result.data.data];
          //this.employeeSelected = result.data.data[0];
        } else {
          const findEmp = this.employeeOption.find(
            (f) => f.userId == this.repairStockData.assignTo
          );        
          this.employeeSelected = findEmp;
        }
      }
    },
    async getHwType() {
      const result = await HardwareService.GetHwTypeList();
      this.hwTypeOption = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwTypeOption.find(
            (f) => f.id == this.repairStockData.hwTypeId
          );
          if(!item){
            item = result.data[0];
          }    
        }     
        this.hwTypeSelected = item;
        await this.onChangeHwType(item);
      }else{
        this.hwTypeSelected = {}
      }
    },

    async getHwBrand(typeId) {
      console.log(typeId)    
      const result = await HardwareService.GetHwBrandList(typeId);
      this.hwBrandOption = result.data;
      

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwBrandOption.find(
            (f) => f.id == this.repairStockData.hwBrandId
          );

          if(!item){
            item = result.data[0];
          }       
        }
       
        this.hwBrandSelected = item;
        await this.onChangeHwBrand(item);
      }else{
        this.hwBrandSelected = {}
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId);
      this.hwModelOption = result.data;

      if (result.data.length > 0) {
        if (this.mode == "create") {
          this.hwModelSelected = result.data[0];
        } else {
          let findHwModel = this.hwModelOption.find(
            (f) => f.id == this.repairStockData.hwModelId
          );
          
          if(!findHwModel){
            findHwModel = result.data[0];
          }  
          this.hwModelSelected = findHwModel;
        }
      }else{
        this.hwModelSelected = {}
      }
    },

    async getCustomerOwner() {
      const result = await CustomerService.GetCustomerOwnerList({});

      this.ownerOption = result.data.data;
      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data.data[0];
        } else {
          item = this.ownerOption.find(
            (f) => f.id == this.repairStockData.ownerId
          );
        }
        this.ownerSelected = item;
        this.onChangeCustomerOwner(item);
      }else{
        this.ownerSelected = {}
      }
    },

    async getCustomerCompany(ownerObj) {
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);
      this.companyOption = result.data.data;

      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data.data[0];
        } else {
          item = this.companyOption.find(
            (f) => f.id == this.repairStockData.companyId
          );

          if(!item){
            item = result.data.data[0];
          }
        }
        this.companySelected = item;
        this.onChangeCustomerCompany(item);
      }else{
        this.companySelected = {}
      }
    },

    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );
      this.subCompanyOption = result.data.data;

      if (result.data.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data.data[0];
        } else {
          item = this.subCompanyOption.find(
            (f) => f.id == this.repairStockData.subCompanyId
          );

          if(!item){
            item = result.data.data[0];
          }
        }
        this.subCompanySelected = item;
        this.onChangeCustomerSubCompany(item);
      }else{
        this.subCompanySelected = {}
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id
      );
      this.branchOption = result.data.data;
      if (result.data.data.length > 0) {
        if (this.mode == "create") {
          this.branchSelected = result.data.data[0];
        } else {
          let findBranch = this.branchOption.find(
            (f) => f.id == this.repairStockData.branchId
          );

          if(!findBranch){
            findBranch = result.data.data[0];
          }
         
          this.branchSelected = findBranch;
        }
      }else{
        this.branchSelected = {}
      }
    },

    async onChangeHwType(item) {
      await this.getHwBrand(item.id);
    },

    async onChangeHwBrand(item) {
      await this.getHwModel(item.id);
    },

    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },
    restoreData() {
      const findStatus = this.statusOption.find(
        (f) => f.value == this.repairStockData.status
      );
      this.statusSelected = findStatus;

      //REPAIR AT
      const findRepairAt = this.repairAtOption.find(
        (f) => f.value == this.repairStockData.repairAt
      );
   
      this.repairAtSelected = findRepairAt || { title: "Vserv", value: "vserv" };
    },
    resetData() {
      this.employeeSelected = { userId: 0, fullname: "-" };
      this.hwTypeSelected = {};
      this.hwBrandSelected = {};
      this.hwModelSelected = {};

      this.statusSelected = { title: "รอการตรวจสอบ", value: "Wait" };
      this.repairAtSelected = { title: "Vserv", value: "vserv" };

      this.ownerSelected = {};
      this.companySelected = {};
      this.subCompanySelected = {};
      this.branchSelected = {};
    },
    hideModal() {
      this.$emit("saveAndCloseModal");
      this.$refs["add-stock-repair-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
